<template>
  <div class="container">
    <h3>{{ $t('bookings.confirmed_title') }}</h3>
    <p>{{ $t('bookings.confirmed_info') }}</p>
  </div>


  <div class="actions">
    <router-link class="next" to="/planning">{{ $t('bookings.finish') }}</router-link>
  </div>
</template>

<script>
export default {
  name: "Finalize"
}
</script>

<style scoped>
.actions {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 10px 20px;
}

.container {
  margin: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 6px;
}

h3 {
  color: #7643D6;
  margin: 0;
}

p{
  color: black;
}

</style>