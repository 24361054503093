<template>
  <div class="container">
    <h3>{{ $t('bookings.choose_tickets') }}</h3>
    <multi-select @change="selected" :items="tickets">
      <template v-slot:default="item">
        {{ !item.item.local_name || item.item.local_name.trim() === '' ? item.item.barcode : item.item.local_name }}
      </template>
    </multi-select>
  </div>


  <div class="actions">
    <button class="next" @click="book" :disabled="bookingProgress">{{ $t('bookings.book') }}</button>
    <router-link class="skip" to="/planning" @click="$router.push('/planning')">{{ $t('actions.close') }}</router-link>
  </div>
</template>

<script>

import MultiSelect from "@/components/Common/Select/MultiSelect";
import {PersistentStore} from "@/services/PersistentStore";
import dayjs from "dayjs";

export default {
  name: "ChooseTicket",
  components: {MultiSelect},
  data() {
    return {
      tickets: [],
      ticketsToBook: [],
      activityId: undefined,
      bookingProgress: false
    }
  },
  async mounted() {
    if (!this.$route.params.id) {
      this.$toast.error(this.$t('bookings.errors.unknown_activity'));
      this.$router.push('/planning')
    }

    const show = await this.$api.shows.byId(this.$route.params.id)

    if (show.isEnded === 1) {
      this.$toast.error(this.$t('bookings.errors.activity_ended'));
      await this.$router.push('/planning')
    }

    if (show.bookings >= show.capacity) {
      this.$toast.error(this.$t('bookings.errors.activity_full'));
      await this.$router.push('/planning')
    }


    const isInLessThan15Mintes = dayjs(show.start.date).unix() * 1000 - 24 * 60 * 60 * 1000 < Date.now()

    const tickets = PersistentStore.tickets.filter(t => t.isValid);

    if (!isInLessThan15Mintes) {
      const validTickets = []
      for(const ticket of tickets){
        const bookings = await this.$api.bookings.byBarcode(ticket.barcode, true)
        const unScannedBookings = bookings.filter(b => !b.scanned_in)
        if(unScannedBookings.length === 0){
          validTickets.push(ticket)
        }
      }

      this.tickets = validTickets;
    } else {
      this.tickets = tickets;
    }

    if (this.tickets.length === 0) {
      this.$toast.error(this.$t('bookings.errors.no_ticket'));
      await this.$router.push('/planning')
    }


    this.activityId = this.$route.params

    if (this.tickets.length === 1) {
      this.ticketsToBook = this.tickets
      // TODO
    }
  },
  methods: {
    selected(tickets) {
      this.ticketsToBook = tickets;
    },
    async book() {
      if(this.bookingProgress){
        return;
      }

      this.bookingProgress = true;
      if (await this.$booking.book(this.ticketsToBook, this.$route.params.id)) {
        this.bookingProgress = false;
        await this.$router.push(`/book/${this.$route.params.id}/finalize`)
      }
      this.bookingProgress = false;
    },
  }
}
</script>

<style scoped>
.actions {
  display: flex;
  flex-direction: row-reverse;
  column-gap: 10px;
  padding: 10px 20px;
}

.container {
  margin: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 6px;
}

h3 {
  color: #7643D6;
  margin: 0;
}

</style>