<template>
  <div class="page" v-if="!loggedin">
    <alert-error>{{ $t('errors.login_impossible') }}</alert-error>
  </div>
</template>

<script>
import {PersistentStore} from "@/services/PersistentStore";
import AlertError from "@/components/Common/System/Alerts/Error";

export default {
  name: "Login",
  components: {AlertError},
  data() {
    return {
      loggedin: true
    }
  },
  async mounted() {
    if(PersistentStore.isStaffLoggedIn){
      PersistentStore.allowedSalles = await this.$api.salles.allowed();
      await this.$router.push("/staff/scan");
      return;
    }

    PersistentStore.staffToken = this.$route.query.token;

    if(PersistentStore.isStaffLoggedIn){
      PersistentStore.allowedSalles = await this.$api.salles.allowed();
      await this.$router.push("/staff/scan");
    }

    this.loggedin = false;
  }
}
</script>

<style scoped>
.page{
  padding: 20px;
}
</style>