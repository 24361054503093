import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from "@/router";
import "@/assets/main.css"
import dayjs from "dayjs";
import "dayjs/locale/fr";
import Api from "@/services/api/Api";
import Toaster from "@meforma/vue-toaster";
import config from "./config.json";
import Booking from "@/services/Booking";
import {PersistentStore} from "./services/PersistentStore";
import { createI18n } from 'vue-i18n'
import translations from './i18n'


const isLocal = window.location.host.includes("localhost");
//const apiUrl = isLocal ? "http://localhost:8000/v1" : "https://planning-api.eventws.ch";
const apiUrl = "https://planning-api.eventws.ch";

const app = createApp(App);

dayjs.locale('fr')

app.config.globalProperties.$dayjs = dayjs
app.config.globalProperties.$isLocal = isLocal

const lang = localStorage.getItem('lang') ?? 'fr'

const i18n = createI18n({
    locale: lang,
    fallbackLocale: 'en',
    messages: translations
})

const api = new Api(apiUrl, config);
app
    .use(api)
    .use(Toaster, {
        position: "top",
        duration: 4000,
        queue: false,
        max: 5,
    })
    .use(new Booking())
    .use(router)
    .use(i18n)
    .mount('#app')

PersistentStore.api = app.config.globalProperties.$api;
