<template>
  <div class="card">
    <qrcode-vue :value="ticket.qrcode" level="H" class="qrcode" render-as="svg" background="#00000000" @click="details = true"/>
    <h2>{{ ticket.local_name }}</h2>
    <bookings :ticket="ticket"></bookings>

    <button class="details" @click="details = true">
      <help-circle-outline></help-circle-outline>
    </button>
    <button class="delete" @click="onDelete">
      <trash-can-outline></trash-can-outline>
    </button>
    <confirm ref="confirm" @validate="$emit('delete', ticket)" title="Confirmation de suppression">
      {{ $t('tickets.confirm_delete', {name:ticket.local_name}) }}
    </confirm>

    <div class="details-pannel" v-if="details" @click="details = false">
      <div class="content">
        <qrcode-vue :value="ticket.qrcode" level="H" class="qrcode" render-as="svg" background="#00000000"/>
        <h1>{{ ticket.local_name }}</h1>
        <p><b>{{ ticket.barcode }}</b> <span class="status" :class="{ error: !status.valid }">{{ status.text }}</span></p>
        <p>{{ ticket.category.name }}</p>
        <p v-if="ticket.valid" v-html="validityDate"></p>
        <div class="right">
          <button class="close" @click="details = false">{{ $t('actions.close') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import {Ticket} from "@/services/api/models/Ticket";
import Bookings from "@/components/Visitor/Bookings";
import {TrashCanOutline, HelpCircleOutline} from "mdue";
import Confirm from "@/components/Common/Dialog/Confirm";

export default {
  name: "Home",
  props: {
    ticket: Ticket
  },
  emits: ["delete"],
  components: {
    Confirm,
    Bookings,
    QrcodeVue,
    TrashCanOutline,
    HelpCircleOutline,
  },
  mounted() {
  },
  data() {
    return {
      reserved: false,
      details: false
    }
  },
  methods: {
    scrollRight() {
      window.scrollTo(0, document.body.s);
    },
    onDelete() {
      this.$refs.confirm.show(this.ticket)
    },
    formatDate(date) {
      return this.$dayjs(date).format("DD.MM.YYYY");
    }
  },
  computed: {
    status() {
      switch (this.ticket.status.toLowerCase()) {
        case "scanned":
          return {"valid": true, "text": this.$t('tickets.status.valid')}
        case "valid":
          return {"valid": false, "text": this.$t('tickets.status.not_scanned')}
        case "Refunded":
          return {"valid": false, "text": this.$t('tickets.status.refunded')}
        case "cancelled":
          return {"valid": false, "text": this.$t('tickets.status.canceled')}
        case "pending":
          return {"valid": false, "text": this.$t('tickets.status.pending')}
      }

      return {"status": false, "text": this.$t('tickets.status.unknown')}
    },
    validityDate(){
      const startDate = this.formatDate(this.ticket.category.validity_start)
      const endDate = this.formatDate(this.ticket.category.validity_end)

      if(startDate === endDate){
        return this.$t('tickets.validity.the', {date: startDate})
      }

      return this.$t('tickets.validity.from_to', {from: startDate, to: endDate})
    }
  }
}
</script>

<style scoped>
.qrcode {
  border-radius: 9px;
  margin: auto;
  display: block;
  max-width: min(25vh, 100%);
  width: 300px;
  height: auto;
  aspect-ratio: 1 / 1;
}

.card {
  background-color: aliceblue;
  min-width: calc(100% - 125px);
  margin: 15px;
  border: solid #7643D6 10px;
  border-radius: 14px;
  padding: 13px 15px 15px;
  scroll-snap-align: center;
  position: relative;
}

.card:only-child{
  min-width: calc(100% - 80px);
}

.card:first-child{
  margin-left: 35px;
}

.card:last-child{
  margin-right: 35px;
}

.book {
  background-color: #7643D6;
  border: none;
  color: white;
  font-weight: bold;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 7px;
  width: 100%;
  margin-top: 20px;
}

.delete {
  background: none;
  color: #c3524f;
  border: none;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 28px;
  border-radius: 7px;
  position: absolute;
  margin: 0;
  top: 0;
  right: 0;
  padding: 9px 7px 20px 20px;
  cursor: pointer;
}

.details {
  background: none;
  color: #7643D6;
  border: none;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 28px;
  border-radius: 7px;
  position: absolute;
  margin: 0;
  top: 0;
  left: 0;
  padding: 9px 20px 20px 7px;
  cursor: pointer;
}


.booking {
  background-color: #7643D6;
  min-height: 30px;
  border-radius: 7px;
  width: 100%;
  margin-top: 20px;
}

h2{
  text-align: center;
  margin: 0;
}

.status{
  margin-top: 20px;
  background-color: #257518;
  width: fit-content;
  color: white;
  padding: 4px 8px;
  font-family: 'Fira Mono', monospace;
  border-radius: 20px;
}

.status.error{
  color: white;
  background-color: #c3524f;
}

.booking h2, .booking p {
  color: white;
}

.booking h2 {
  margin: 0;
}

.booking p {
  margin: 0;
}

.details-pannel {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(30, 30, 30, 0.91);
  height: 100vh;
  width: 100vw;
  z-index: 10;
}

.details-pannel .content{
  background-color: white;
  width: calc(100vw - 50px);
  padding: 15px;
  margin: 10px;
  border-radius: 6px;
}

.details-pannel .content button{
  margin: 0;
}
.details-pannel .content .right{
  display: flex;
  justify-content: right;
}

.details-pannel .qrcode{
  max-width: 100%;
  width: 100%;
}

h1{
  margin: 15px 0;
}

.card > h2{
  margin-bottom: 15px;
}
</style>
