<template>
  <div class="page shadow" v-if="allowed !== undefined">
    <h2>Queues</h2>
    <template  v-if="allowed">
      <div class="queue" v-for="queue in queues" :key="queue.id">
        <h4>{{queue.name}}</h4>
        <select @change="selectShow($event, queue.id)">
          <option :value="null" :selected="!queue.showId"></option>
          <option
              v-for="show in queue.shows"
              :key="show.id"
              :value="show.id"
              :selected="show.id === queue.showId"

          >{{show.name}} - {{$dayjs(show.start.date).format('HH[h]mm')}}</option>
        </select>
      </div>
    </template>
    <div v-else>
      <alert-info>{{ $t('queues.errors.edit_not_allowed') }}</alert-info>
    </div>
  </div>
</template>

<script>
import {PersistentStore} from "../../services/PersistentStore";
import AlertInfo from "../Common/System/Alerts/Info";

export default {
  name: "Queue",
  components: {AlertInfo},
  data() {
    return {
      queues: [],
      shows: [],
      allowed: undefined,

    }
  },
  async mounted() {
    PersistentStore.allowedSalles = await this.$api.salles.allowed();

    const allowedSalleIds = PersistentStore.allowedSalles;
    const salleIds = [35, 39, 40, 41].filter(id => allowedSalleIds.includes(id));

    this.allowed = salleIds.length !== 0;

    this.queues = (await this.$api.queues.all())?.data ?? [];
    this.shows = ((await this.$api.shows.all()) ?? []).sort((a, b) => this.$dayjs(a.start.date).unix() - this.$dayjs(b.start.date).unix());

    this.queues.map(q => {
      q.shows = this.shows.filter(s => [35,39,40,41].includes(s.salle))
      return q
    })
  },
  methods: {
    async selectShow(event, queueId){
      await this.$api.queues.setShow(event.target.value, queueId)
    }
  }
}
</script>

<style scoped>
.page{
  background-color: white;
  margin: 20px;
  padding: 20px;
  border-radius: 6px;
}

select {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
}

h4{
  margin: 0 0 5px;
}
</style>