<template>
  <div class="container">
    <img class="logo" src="@/assets/logo.svg">
    <h1>{{ $t('onboarding.welcome', { year: new Date().getFullYear() }) }}</h1>
    <fieldset class="langs">
      <div v-for="locale in $i18n.availableLocales.reverse()" :key="`locale-${locale}`">
        <input type="radio" name="lang" :value="locale" :id="locale" v-model="$i18n.locale" @change="langSelected(locale)" />
        <label :for="locale">{{ langs[locale] ?? locale }}</label>
      </div>
    </fieldset>
    <p>{{ $t('onboarding.text') }}</p>
  </div>
  <div class="actions">
    <router-link class="next" to="/in-boarding/add-qr">{{ $t('tickets.scan') }}</router-link>
    <router-link class="skip" to="/planning" @click="PersistentStore.skipInBoarding = true">{{ $t('onboarding.to_planning') }}</router-link>
  </div>
</template>

<script>
import {PersistentStore} from "@/services/PersistentStore";

export default {
  name: "About",
  data() {
    return {
      PersistentStore,
      langs: {
        fr: "Français",
        en: "English",
        de: "Deutsch",
      }
    }
  },
  components: {
  },
  mounted() {

  },
  methods: {
    langSelected(locale) {
      localStorage.setItem('lang', locale)
    }
  }
}
</script>

<style scoped>
h1{
  font-size: min(6vw, 2em);
}
fieldset {
  margin: 10px 20px;
  padding: 0;
  display: flex;
  border-radius: 6px;
  border: 2px solid white;
  width: fit-content;
}

fieldset > div{
  display: flex;
}

fieldset input[type="radio"]{
  position: fixed;
  opacity: 0;
  pointer-events: none;
}


fieldset label {
  padding: 5px 12px;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  text-decoration: none;
  align-self: flex-end;
}

fieldset input[type="radio"]:checked + label{
  background: none;
  background-color: #fff;
  border: none;
  color: #7643D6;
}

.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 10px 20px;
}

.logo{
  width: 100%;
  display: block;
  filter: grayscale(1) invert(1);
}

.container > *{
  margin: 0;
}

.actions{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 10px 20px;
}

</style>