<!-- Home page listing all QR codes -->

<template>
  <pull-refresh
      v-model="loading"
      @refresh="onRefresh"
      :loading-text="$t('pull_refresh.updating')"
      :pulling-text="$t('pull_refresh.pull')"
      :loosing-text="$t('pull_refresh.release')"
      success-duration="0"
  >
    <div class="container">
      <div v-if="tickets.length <= 0" class="page">
        <alert-info>
          {{ $t('tickets.none_registered') }}
          <button class="small outline info" @click="add">{{ $t('tickets.add') }}</button>
        </alert-info>
      </div>
      <div class="paginated" ref="scrollable" v-else>
        <div class="tickets">
          <QRCard v-for="item in tickets" :key="item.value" :ticket="item" @delete="onDelete"></QRCard>
        </div>
      </div>
      <button class="add" @click="add"><plus></plus></button>
    </div>


  </pull-refresh>
</template>

<script>
import QRCard from "@/components/Visitor/QRCard";
import {PersistentStore} from "@/services/PersistentStore";
import AlertInfo from "@/components/Common/System/Alerts/Info";
import PullRefresh from 'pull-refresh-vue3'

import { Plus } from "mdue";


export default {
  name: "Tickets",
  components: {
    AlertInfo,
    QRCard,
    Plus,
    PullRefresh
  },
  mounted() {
    this.tickets = PersistentStore.tickets;
  },
  data() {
    return {
      value: 'https://example.com',
      tickets: [ ],
      shouldScroll: false,
      loading: true,
    }
  },
  updated() {
    this.scrollRight();
  },
  methods:{
    add(){
      this.$router.push('/tickets/add')
    },
    scrollRight() {
      if(!this.shouldScroll) return;
      this.$refs.scrollable.scrollTo({
        top: 0,
        left: this.$refs.scrollable.scrollWidth,
        behavior: 'smooth'
      })
    },
    onDelete(ticket){
      PersistentStore.removeTicket(ticket)

      this.tickets = PersistentStore.tickets
      // Go back to in-boarding if no ticket
      this.$router.go()
    },
    async onRefresh(){
      await this.refreshTickets();
      this.loading = false;
    },
    async refreshTickets() {
      const tickets = await Promise.all(this.tickets.map(ticket => this.$api.tickets.getById(ticket.id)))
      PersistentStore.updateTickets(tickets);
      this.tickets = PersistentStore.tickets;
    }
  }
}
</script>

<style scoped>
.tickets{
  display: flex;
  flex-direction: row;
  scroll-snap-type: x mandatory;
  overflow:auto;
  width: 100%;
}

.tickets::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #efefef;
  border-radius: 10px;
  border: solid 4px transparent;
}


.add{
  background: linear-gradient(47deg, #6f00aa 0%, #900ea1 100%);
  border: none;
  color: white;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  display: block;
  position: fixed;
  bottom: 70px;
  right: 10px;
  font-size: 16px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  padding: 0;
}

.add svg{
  font-size: 33px;
  transform: translateY(1px);
}

.page{
  margin: 20px;
}

.container{
  height: 100vh;
  max-height: 100%;
  overflow-y: auto;
}
</style>