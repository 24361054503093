export default {
    words: {
        or: 'ou',
            confirm: 'Confirmation',
    },
    actions: {
        close: "Fermer",
            save: "Enregistrer",
            check: "Vérifier",
            yes: "Oui",
            no: "Non",
            validate: "Valider",
            back: "Retour",
            logout: "Déconnexion",
            finish: "Terminer",
        continue: "Continuer",
    },
    menu: {
        tickets: 'Billets',
            signings: 'Dédicaces',
            planning: 'Programme',
            infos: 'Infos',
            scan: 'Scan',
            activities: 'Activités',
            queues: 'Queues',
            help: 'Aide',
            go_to_staff_app: 'Application Staff',
            go_to_visitor_app: 'Application visiteur',
    },
    tools: {
        title: "Outils",
            version: "Version: {version}",
            reset: "Réinitialiser l'application",
    },
    tickets: {
        none_registered: 'Tu n\'as aucun billet enregistré',
            add: 'Ajouter un billet',
            scan: 'Scanner mon billet',
            number: 'Numéro du billet',
            visitorName: 'Nom du visiteur',
            verify: 'Vérifier',
            validation_impossible: 'Impossible de valider le ticket.',
            scan_successful: 'Le billet <i>{barcode}</i> a été scanné correctement.<br>Tu peux lui donner un nom pour le reconnaître facilement.',
            confirm_delete: 'Voulez-vous vraiment supprimer le ticket \'{name}\'',
            validity: {
            not_yet: 'Le billet n\'est pas encore valide.',
                expired: 'Le billet n\'est plus valide.',
                canceled: 'Le billet est annulé',
                the: 'Valable le <b>{date}</b>',
                from_to: 'Valable de <b>{startDate}</b> au <b>{endDate}</b>'
        },
        errors: {
            not_scanned: "Le billet n'a pas encore été scanné à l'entrée",
                invalid: "Le billet n'est pas valable",
                invalid_today: "Le billet n'est pas valable aujourd'hui",
                none_linked_to_qr: "Aucun ticket lié à ce QR Code",
                non_existent: "Le billet n'existe pas",
        },
        status: {
            valid: "Valable",
                not_scanned: "Non scanné",
                refunded: "Remboursé",
                canceled: "Annulé",
                pending: "En attente",
                unknown: "Status inconnu",
        }
    },
    events:{
        none_for_category: 'Aucun événement prévu pour cette catégorie'
    },
    bookings: {
        your_number: 'Votre numéro:',
            called: 'Appelé',
            book: 'Reserver',
            confirm_cancel: 'Voulez-vous vraiment annuler cette reservation ?',
            enabled_from: 'La réservation sera possible à paritir de {date}',
            confirm_close: "Voulez-vous vraiment fermer les reservations ? L'activité ne sera plus disponible aux visiteurs",
            staff_book_activity_with_ticket: 'Activité avec ticket: transmettre au visiteur le numéro',
            choose_tickets: "Choissez les tickets à reserver",
            confirmed_title: "Réservation enregistrée",
            confirmed_info: "Merci de vous présenter 15 minutes avant au maximum",
            cancelled: 'Réservation annulée',
            scanning: {
            start: 'Commencer le scanning',
                end: 'Terminer le scanning',
                call_next: 'Appeler le prochain {next}',
                choose_room_to_start_checkin: 'Choisis ta salle ci-dessus afin de commencer la validation',
                created_automatically: "Réservation créée automatiquement"
        },
        stats: {
            title: 'Statistiques des réservations',
                capaacity: 'Capacité',
                bookings: 'Réservations',
                scanned: 'Scanné',
        },
        errors: {
            no_booking_for_open: "Aucun booking pour une salle avec scanning ouvert",
                already_scanned: "Billet déjà scanné pour cette activité",
                no_valid_booking: "Aucune réservation valide pour ce billet",
                scan_disallowed: "Tu n'as pas le droit de valider un billet",
                unknown_activity: "Activité inconnue",
                activity_full: "Activité complète",
                activity_ended: "Activité terminée",
                no_ticket: "Tu n'as aucun ticket pouvant recevoir une réservation",
        }
    },
    queues: {
        errors:{
            edit_not_allowed: 'Tu n\'es pas autorisé à modifier les queues'
        }
    },
    onboarding: {
        welcome: 'Bienvenue à Polymanga {year} !',
            text: 'Vous pouvez réserver votre place pour les dédicaces de votre choix et ainsi éviter de patienter dans les files d’attentes ! Une fois votre place assurée, vous profitez du festival et venir sur place quelques minutes avant le début pour entrer. Scannez, réservez, profitez !',
            to_planning: 'Aller au programme',
    },
    permissions: {
        no_camera: 'Aucune caméra n\'a été trouvée ou l\'autorisation a été refusée. Tu peux saisir le numéro du billet ci-dessous.',
    },
    errors: {
        missing_internet: 'Tu n\'est pas connecté à internet. Les information affichées ne sont pas à jour!',
            login_impossible: 'Connexion impossible',
    },
    count_down: {
        bookable_in: "Réservable dans {remaining}"
    },
    pull_refresh: {
        updating: "Mise à jour...",
            pull: "Tirer pour rafraîchir",
            release: "Relacher pour rafraîchir",
    }
}
