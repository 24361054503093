<template>
  <alert-success v-if="isScanned" v-html="$t('tickets.scan_successful', {barcode: ticket.barcode})"></alert-success>
  <alert-error v-if="failed">
    {{ $t('tickets.validation_impossible') }}
  </alert-error>

  <template v-if="!isScanned">
    <qr-code-scanner @scan="onScan($event)"></qr-code-scanner>
    <div id="divider">{{ $t('words.or') }}</div>
    <button-input :placeholder="$t('tickets.number')" :action="$t('actions.check')"
                  @input.self="onBarcodeInput"></button-input>
  </template>

  <input v-if="isScanned" @input="onNameChange" :placeholder="$t('tickets.visitorName')">
</template>

<script>
import QrCodeScanner from "@/components/Common/TicketInput/QrCodeScanner";
import AlertSuccess from "@/components/Common/System/Alerts/Success";
import AlertError from "@/components/Common/System/Alerts/Error";
import ButtonInput from "@/components/Common/System/Inputs/ButtonInput";


export default {
  name: "TicketInput",
  data() {
    return {
      name: "",
      ticket: null,
      failed: false,
    }
  },
  components: {
    ButtonInput,
    QrCodeScanner,
    AlertSuccess,
    AlertError,
  },
  computed: {
    isScanned() {
      return !!this.ticket
    },
    isValid() {
      return this.isScanned && this.ticket.valid
    }
  },
  methods: {
    async onScan(code) {
      const ticket = await this.getTicketByQr(code)
      if (!this.checkTicket(ticket)) return;
      this.ticket = ticket

      this.failed = !this.ticket;

      this.emitTicket()
    },
    async onBarcodeInput(barcode) {
      const ticket = await this.getTicketByBarcode(barcode)
      if (!ticket || !this.checkTicket(ticket)) return;
      this.ticket = ticket
      this.failed = !this.ticket;

      this.emitTicket()
    },
    onNameChange(arg) {
      this.name = arg.target.value
      if (this.ticket)
        this.ticket.local_name = this.name;

      this.emitTicket()
    },
    emitTicket() {
      let name = this.name;
      if (!name || name.trim() === "")
        name = this.code;

      this.$emit('scan', this.ticket)
    },
    checkTicket(ticket) {
      if (ticket.status === 'valid') {
        this.$toast.error(this.$t('tickets.errors.not_scanned'));
        return false;
      }

      if (ticket.status !== 'scanned') {
        this.$toast.error(this.$t('tickets.errors.invalid'));
        return false;
      }

      return true;
    },
    async getTicketByQr(qrcode) {
      const tickets = this.$api.tickets
      try {
        return await tickets.getByQrcode(qrcode)
      } catch (ex) {
        this.$toast.error(this.$t('tickets.errors.none_linked_to_qr'))
        return null
      }
    },
    async getTicketByBarcode(qrcode) {
      const tickets = this.$api.tickets
      try {
        return await tickets.getByBarcode(qrcode)
      } catch (ex) {
        return null
      }
    }
  },
  emits: ["scan"]
}
</script>

<style scoped>
#divider {
  text-align: center;
  font-size: 1.2em;
}

#divider:after, #divider:before {
  content: '';
  background-color: #ffffff;
  display: inline-block;
  height: 1px;
  width: 20%;
  max-width: 77px;
  margin-bottom: 5px;
}

#divider:after {
  margin-left: 36px;
}

#divider:before {
  margin-right: 36px;
}
</style>