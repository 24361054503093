
<template>
  <div class="page">
    <div class="logo">
      <img src="@/assets/logo.svg">
    </div>
    <div class="scrollable">
      <div class="pannel" v-html="html"></div>

      <div class="pannel" >
        <h2>Staff</h2>
        <button @click="$router.push('/')">{{ $t('menu.go_to_visitor_app') }}</button>
        <button class="delete" @click="logoff">{{ $t('actions.logout') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {PersistentStore} from "@/services/PersistentStore";

export default {
  name: "InfosStaff",
  data() {
    return{
      isStaff: false,
      html: "<h1>test</h1>"
    }
  },
  mounted() {
    this.isStaff = PersistentStore.isStaffLoggedIn;
    this.$api.infos.staff().then((html) => this.html = html);
  },
  methods: {
    logoff() {
      PersistentStore.logOffStaff()
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.pannel {
  margin: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 6px;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;

}

button{
  display: block;
}

.delete{
  background-color: #c3524f;
}

.pannel h2, .pannel h1{
  margin-top: 0;
  margin-bottom: 10px;
}

.logo{
  height: calc(100% - 16px);
  width: calc(100% - 16px);
  background: linear-gradient(47deg, #432ed1 0%, #2e35d1 100%);
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img{
  height: auto;
  max-width: calc(100% - 40px);
  max-height: calc(100% - 20px);
  filter: invert(100%);
}



.page{
  display: grid;
  grid-template-rows: 70px 1fr;
  height: 100%;
}

.scrollable{
  height: 100%;
  overflow-x: auto;
}
::-webkit-scrollbar {
  width: 8px;
  border-radius: 0px!important;
}

::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2e35d0;
}
</style>


